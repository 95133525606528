'use client';

import {
  useCallback,
  type ButtonHTMLAttributes,
  type ChangeEvent,
  type PropsWithChildren,
} from 'react';
import { IconButton, Tooltip } from '@radix-ui/themes';
import { Root as Form } from '@radix-ui/react-form';
import { LocateFixedIcon } from 'lucide-react';

import { PROPERTY_ADDRESS_FIELD_NAME } from '@/constants/client/field-names';
import { SearchField, SearchIconLabel } from '@/components/forms/Search';
import { Combobox } from '@/components/forms/Combobox';
import { useLocation } from '@/hooks/useLocation';

const i18n = {
  en: {
    labels: {
      geolocate: 'Use my location',
    },
    fields: {
      address: {
        label: 'Street Address',
        placeholder: 'Enter your address',
      },
    },
  },
} as const;

export function AddressGeolocation(
  props: Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'color'>,
) {
  // TODO: check permissions
  // TODO: use alert dialogue if not accepted
  // TODO: otherwise run functionality and reverse geocode, finally persist as address
  return (
    <Tooltip content={i18n.en.labels.geolocate} role="decorative">
      <IconButton
        variant="ghost"
        type="button"
        aria-label={i18n.en.labels.geolocate}
        mr="-1"
        {...props}
      >
        <LocateFixedIcon />
      </IconButton>
    </Tooltip>
  );
}

export default function AddressSearchField({
  value: controlledValue,
  onChange: onChangeProp,
  onSelect: onSelectProp,
  formState,
}: {
  value?: string;
  onChange?: (value: string) => void;
  onSelect?: (address: string) => void;
  formState?: {
    isLoading?: boolean;
    isSubmitting?: boolean;
  };
}) {
  const {
    // @ts-expect-error
    isSubmitting = formState?.isSubmitting,
    isLoading = formState?.isLoading,
    selection,
    value,
    suggestions,
    onChange,
    onSelect,
    getAddressFromCurrentPosition,
  } = useLocation();

  const onSelectionChange = useCallback(
    (nextSelection: string) => {
      onSelect(nextSelection);
      onSelectProp?.(nextSelection);
    },
    [onSelect, onSelectProp],
  );

  const onInputChange = useCallback(
    (event: ChangeEvent) => {
      const nextValue = (event.target as HTMLInputElement).value;
      onChange(nextValue);
      onChangeProp?.(nextValue);
    },
    [onChange, onChangeProp],
  );

  const onGeolocate = useCallback(
    () => getAddressFromCurrentPosition().then(onSelectionChange),
    [getAddressFromCurrentPosition, onSelectionChange],
  );

  return (
    <Combobox
      as={SearchField}
      name={PROPERTY_ADDRESS_FIELD_NAME}
      placeholder={i18n.en.fields.address.placeholder}
      className="mx-auto w-full max-w-xl py-1"
      options={suggestions}
      value={controlledValue ?? value}
      onSelect={onSelectionChange}
      onChange={onInputChange}
    >
      <SearchIconLabel label={i18n.en.fields.address.label} />
      <AddressGeolocation
        disabled={isLoading || isSubmitting}
        onClick={onGeolocate}
      />
    </Combobox>
  );
}

export function AddressSearchForm({
  children,
  className,
  value,
  onChange,
  onSubmit,
  onSelect,
}: PropsWithChildren<{
  value?: string;
  className?: string;
  onChange?: (value: string) => void;
  onSubmit?: (event: Event, formData: FormData) => void;
  onSelect?: (address: string) => void;
}>) {
  return (
    // @ts-expect-error
    <Form
      className={className}
      onSubmit={(event: Event) => {
        if (onSubmit)
          onSubmit(event, new FormData(event.target as HTMLFormElement));
        else {
          event.preventDefault();
        }
      }}
    >
      <AddressSearchField
        value={value}
        onChange={onChange}
        onSelect={onSelect}
      />
      {children}
    </Form>
  );
}
