'use client';

import { type ChangeEvent } from 'react';
import { Button, Text } from '@radix-ui/themes';
import { Root as Form, Submit } from '@radix-ui/react-form';
import { Controller, useForm } from 'react-hook-form';

import { PROPERTY_ADDRESS_FIELD_NAME } from '@/constants/client/field-names';
import { SearchField, SearchIconLabel } from '@/components/forms/Search';
import { Combobox } from '@/components/forms';
import { useLocation } from '@/hooks/useLocation';
import { AddressGeolocation } from './AddressSearch';

const i18n = {
  en: {
    labels: {
      geolocate: 'Use my location',
    },
    fields: {
      address: {
        label: 'Street Address',
        placeholder: 'Enter your address',
      },
    },
    actions: {
      submit: 'See prices',
    },
  },
} as const;

export default function PropertySearchForm({
  onSubmit,
  addressLabel = i18n.en.fields.address.label,
  addressPlaceholder = i18n.en.fields.address.placeholder,
  submitLabel = i18n.en.actions.submit,
  geolocateLabel = i18n.en.labels.geolocate,
}: {
  onSubmit: (data: any) => Promise<void> | void;
  addressLabel?: string;
  addressPlaceholder?: string;
  submitLabel?: string;
  geolocateLabel?: string;
}) {
  const {
    control,
    formState: { isSubmitting },
    handleSubmit,
  } = useForm();

  const {
    isLoading,
    selection,
    value,
    suggestions,
    onChange,
    onSelect,
    getAddressFromCurrentPosition,
  } = useLocation();

  return (
    // @ts-expect-error
    <Form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-6">
      <Controller
        name={PROPERTY_ADDRESS_FIELD_NAME}
        control={control}
        render={({ field }) => (
          <Combobox
            as={SearchField}
            name={PROPERTY_ADDRESS_FIELD_NAME}
            placeholder={addressPlaceholder}
            className="mx-auto w-full max-w-xl py-1"
            options={suggestions}
            value={value}
            onSelect={(nextSelection: string) => {
              onSelect(nextSelection);
              field.onChange(nextSelection);
            }}
            onChange={(event: ChangeEvent) => {
              const nextValue = (event.target as HTMLInputElement).value;
              onChange(nextValue);
              field.onChange(nextValue);
            }}
          >
            <SearchIconLabel label={geolocateLabel} />
            <AddressGeolocation
              disabled={isLoading || isSubmitting}
              onClick={() =>
                getAddressFromCurrentPosition().then((address) => {
                  field.onChange(address);
                  return onSelect(address);
                })
              }
            />
          </Combobox>
        )}
      />

      <div className="flex w-full justify-center">
        {/* @ts-expect-error */}
        <Submit asChild>
          <Button
            disabled={!selection || isLoading || isSubmitting}
            type="submit"
            variant="solid"
            size="3"
            className="w-[200px] max-w-sm"
          >
            <Text as="span" weight="bold">
              {submitLabel}
            </Text>
          </Button>
        </Submit>
      </div>
    </Form>
  );
}
