'use client';

import type { Route } from 'next';
import Link from 'next/link';
import { Link as Anchor, Flex, Heading, Section, Text } from '@radix-ui/themes';

import { api } from '@/data/client';
import StandaloneEmailForm from '@/views/forms/StandaloneEmail';
import { useToaster } from '~/src/components/radix';

const i18n = {
  en: {
    title: 'Stay in touch!',
    subtitle:
      'Join our newsletter for exclusive deals, new guides and tips to take care of your lawn.',
    privacyNotice: 'We care about your data.',
    links: {
      privacy: 'Read our Privacy Policy',
    },
  },
  links: {
    privacy: '/privacy' as Route,
  },
};

export default function NewsletterWidget() {
  const toaster = useToaster();
  const subscribeToNewsletter = api.public.subscribeToNewsletter.useMutation({
    onSuccess() {
      toaster.addToast({
        title: 'Thank you for subscribing!',
      });
    },
    onError(error) {
      toaster.addToast({
        title: 'Failed to subscribe',
        description: error.message,
      });
    },
  });

  return (
    <Section>
      <Flex direction="column" gap="6">
        <header className="mx-auto w-full max-w-xl px-4 sm:text-center lg:px-6">
          <Heading as="h2" size="8" weight="bold" className="mb-4">
            <span className="text-4xl font-extrabold tracking-tight">
              {i18n.en.title}
            </span>
          </Heading>
          <Text size="5" weight="light" color="gray">
            <span className="mb-8 font-light sm:text-xl lg:mb-16">
              {i18n.en.subtitle}
            </span>
          </Text>
        </header>

        <div className="mx-auto w-full max-w-xl p-4">
          <div className="mb-2 w-full">
            <StandaloneEmailForm
              onSubmit={(data) => {
                subscribeToNewsletter.mutate(data);
              }}
            />
          </div>

          <Text size="2" color="gray">
            {i18n.en.privacyNotice}{' '}
            <Anchor asChild color="grass">
              <Link href={i18n.links.privacy}>{i18n.en.links.privacy}.</Link>
            </Anchor>
          </Text>
        </div>
      </Flex>
    </Section>
  );
}
